<template>
  <div class="choose_scene">
    <i-nav-bar :iTitle="title"></i-nav-bar>
    <div class="main">
      <div class="all_scene">
        <!-- 场景 -->
        <div
          class="scene_item mgt40"
          v-for="(item, index) in sceneList"
          :key="index"
        >
          <div class="item_header">
            <img
              class="header_img"
              :src="
                require(`./../../assets/images/scene/pic_title_img${item.type}.png`)
              "
              alt=""
            />
          </div>
          <div class="item_body">
            <div class="scene_list">
              <div
                v-for="(item1, index) in item.scene_data"
                :key="index"
                class="scene_item"
                @click="onSelect(item1, item.type)"
              >
                <div class="item_box">
                  <div
                    :class="`img_box ${
                      isSelectedId == item1.scene_id ? 'box_border' : ''
                    }`"
                  >
                    <!-- :src="item1.img_url" -->
                    <!-- <img
                      class="icon_img"
                      src="./../../assets/images/ido/icon_scene.png"
                      alt=""
                    /> -->
                    <img class="icon_img" :src="item1.img_url" alt="" />
                  </div>
                  <div
                    :class="`scene_txt ${
                      isSelectedId == item1.scene_id ? 'txt_c' : ''
                    }`"
                  >
                    {{ item1.scene_name }}
                  </div>
                </div>
              </div>

              <div
                class="scene_item"
                @click="
                  onSelect(
                    sceneForm[`scene_data${item.type}`],
                    sceneForm[`scene_data${item.type}`].type
                  )
                "
                v-if="
                  sceneForm[`scene_data${item.type}`].scene_name &&
                  item.type == sceneForm[`scene_data${item.type}`].type
                "
              >
                <div class="item_box">
                  <div
                    :class="`img_box ${
                      isSelectedId ==
                      sceneForm[`scene_data${item.type}`].scene_id
                        ? 'box_border'
                        : ''
                    }`"
                  >
                    <!-- :src="item1.img_url" -->
                    <!-- <img
                      class="icon_img"
                      src="./../../assets/images/ido/icon_scene.png"
                      alt=""
                    /> -->
                    <img
                      class="icon_img"
                      :src="
                        require('./../../assets/images/scene/pic_scene_default.png')
                      "
                      alt=""
                    />
                  </div>
                  <div
                    :class="`scene_txt ${
                      isSelectedId ==
                      sceneForm[`scene_data${item.type}`].scene_id
                        ? 'txt_c'
                        : ''
                    }`"
                  >
                    {{ sceneForm[`scene_data${item.type}`].scene_name }}
                  </div>
                </div>
              </div>

              <div class="scene_item" @click="openModal(item.type)">
                <div class="item_box">
                  <div class="img_box add_box">
                    <!-- :src="item1.img_url" -->
                    <img
                      class="icon_img add_img"
                      src="./../../assets/images/ido/icon_add.png"
                      alt=""
                    />
                  </div>
                  <div class="`scene_txt txt_c">新增</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <add-scene
      :isShow.sync="isOpenModal"
      :add-type="sceneType"
      @addScene="handleAdd"
    ></add-scene>

    <div class="safe_distance"></div>

    <div class="footer">
      <van-button class="footer_btn btn1" color="#111111" round @click="goBack">
        <img
          class="left_img"
          src="./../../assets/images/ido/pic_left.png"
          alt=""
        />
      </van-button>
      <van-button
        v-if="isDisabled"
        class="footer_btn btn2 mgl10"
        @click="toNext"
        color="#4BFFF2"
        round
        >继续
        <img
          class="left_img"
          src="./../../assets/images/ido/pic_right.png"
          alt=""
        />
      </van-button>
      <van-button
        v-else
        class="footer_btn btn3 mgl10"
        disabled
        color="#4BFFF2"
        round
        >请选择场景
      </van-button>
    </div>
  </div>
</template>

<script>
import iNavBar from "@/components/iNavBar.vue";
import AddScene from "./AddScene.vue";
import { getSceneData } from "@/request/api";
import { judgeImgEffective } from "@/utils/utils";
export default {
  name: "Scene",
  data() {
    return {
      judgeImgEffective,
      title: "选择场景",
      sceneList: [],
      allSceneList: [],
      isDisabled: false,
      isSelected: false,
      isSelectedList: [],
      isOpenModal: false,
      sceneType: 1,
      isSelectedId: 0,
      sceneForm: {
        scene_data1: {
          scene_id: 9998,
          scene_name: "",
          scene_desc: "",
        },
        scene_data2: {
          scene_id: 9999,
          scene_name: "",
          scene_desc: "",
        },
      },
    };
  },
  components: {
    iNavBar,
    AddScene,
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getData();
      let localObj = JSON.parse(
        window.localStorage.getItem("submitInfo")
      )?.sceneObj;
      this.isSelectedId = localObj ? localObj.scene_id : 0;
      this.isSelectedList = localObj ? [localObj] : [];
      this.isDisabled = !!localObj;
    },
    async getData() {
      let data = await getSceneData();
      console.log("获取到的场景信息", data);
      if (data.rs_code == 1000) {
        this.sceneList = data.details;
        // 1：设置给自己；2：设置给亲友
        this.allSceneList = this.getAllSceneList(data.details);
        console.log("所有场景", this.allSceneList);
      }
    },

    // 数组拍平
    getAllSceneList(arr) {
      let newArr = [];
      arr.forEach((item1) => {
        item1.scene_data.forEach((item2) => {
          let obj = {
            type: item1.type,
            isSelected: false,
            ...item2,
          };
          newArr.push(obj);
        });
      });
      return newArr;
    },

    // 打开弹窗
    openModal(type) {
      console.log("新增类型", type);
      this.sceneType = type;
      this.isOpenModal = true;
    },
    //
    handleAdd(obj) {
      this.sceneForm[`scene_data${this.sceneType}`] = {
        scene_id: this.sceneType == 1 ? 9998 : 9999,
        scene_name: JSON.parse(JSON.stringify(obj)).scene_name,
        scene_desc: JSON.parse(JSON.stringify(obj)).scene_desc,
        type: this.sceneType,
      };
      console.log("获取新增的场景", this.sceneForm);
    },

    // 选中的场景
    onSelect(obj, type) {
      // let bol = this.isSelectedList.some(
      //   (item) => item.scene_id == obj.scene_id
      // );
      // if (bol) {
      //   this.isSelectedList = this.isSelectedList.filter(
      //     (item) => item.scene_id != obj.scene_id
      //   );
      //   this.$set(obj, "isSelected", false);
      //   this.isDisabled = this.isSelectedList.length > 0;
      // } else {
      //   this.isSelectedList.push(obj);
      //   this.isDisabled = this.isSelectedList.length > 0;
      //   this.$set(obj, "isSelected", true);
      // }
      this.isSelectedList = [];
      if (this.isSelectedId != obj.scene_id) {
        this.isSelectedList.push(obj);
      }
      this.isSelectedId = this.isSelectedId == obj.scene_id ? 0 : obj.scene_id;
      this.isDisabled = this.isSelectedId != 0;
      obj.type = type;
      console.log("被选中的", obj, type);
    },
    toNext() {
      LA.track('scenenextbtn_click');
      _hmt.push(["_trackEvent", "scenenextbtn_click", "click"]);
      console.log("选中的场景", this.isSelectedList);
      let data = {
        ...JSON.parse(window.localStorage.getItem("submitInfo")),
        sceneObj: this.isSelectedList[0],
      };
      window.localStorage.setItem("submitInfo", JSON.stringify(data));
      this.$router.push({
        path: "/voice",
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.choose_scene {
  width: 100%;
  min-height: 100%;
  background: #5257cf;

  .mgt40 {
    margin-top: 40px !important;
  }
  .main {
    margin-top: 36px;
    .all_scene {
      width: 100%;
      min-height: 100px;
      .item_body {
        padding: 0 14px;
        .scene_list {
          display: flex;
          flex-flow: wrap;
          margin: 0 auto;
        }
        .scene_item {
          margin-top: 15px;
          margin-right: 11px;
          .item_box {
            width: 60px;
            //   height: 60px;
            .img_box {
              position: relative;
              width: 61px;
              height: 61px;
              border: 1px solid #6f87ff;
              box-sizing: border-box;
            }

            .add_box {
              background: #6f87ff;
            }
            .icon_img {
              position: absolute;
              width: 41px;
              height: 41px;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
            }
            .add_img {
              width: 20px;
              height: 20px;
            }

            .scene_txt {
              margin-top: 6px;
              font-size: 12px !important;
              font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
              font-weight: normal;
              color: #ffffff;
              text-align: center;
            }
          }
          // background-color: yellow;
          &:nth-child(5n) {
            margin-right: 0;
          }
        }
      }

      .header_img {
        height: 34px;
        margin-left: 5px;
        object-fit: cover;
      }
    }
  }

  .box_border {
    border: 2px solid #4bfff2 !important;
  }

  .txt_c {
    margin-top: 6px;
    font-size: 12px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    text-align: center;
    color: #4bfff2 !important;
  }
}

.safe_distance {
  height: 57px;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
}

.footer {
  box-sizing: border-box;
  border-top: 0.5px solid #484dc6;
  background-color: #5257cf;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  width: 100%;
  padding: 10px 20px;
  z-index: 10;

  .footer_btn {
    width: 100%;
    height: 37px;

    .van-button__text {
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #111111;
      line-height: 20px;
    }
  }
  .left_img {
    width: 22px;
    height: 10px;
    object-fit: contain;
  }
  .btn1 {
    position: relative;
    width: 120px;
    background: #111111;
    .van-button__text {
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #4bfff2;
      line-height: 20px;
    }
  }
  .btn2 {
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #111111;
  }
}
</style>