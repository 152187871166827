var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"choose_scene"},[_c('i-nav-bar',{attrs:{"iTitle":_vm.title}}),_c('div',{staticClass:"main"},[_c('div',{staticClass:"all_scene"},_vm._l((_vm.sceneList),function(item,index){return _c('div',{key:index,staticClass:"scene_item mgt40"},[_c('div',{staticClass:"item_header"},[_c('img',{staticClass:"header_img",attrs:{"src":require(("./../../assets/images/scene/pic_title_img" + (item.type) + ".png")),"alt":""}})]),_c('div',{staticClass:"item_body"},[_c('div',{staticClass:"scene_list"},[_vm._l((item.scene_data),function(item1,index){return _c('div',{key:index,staticClass:"scene_item",on:{"click":function($event){return _vm.onSelect(item1, item.type)}}},[_c('div',{staticClass:"item_box"},[_c('div',{class:("img_box " + (_vm.isSelectedId == item1.scene_id ? 'box_border' : ''))},[_c('img',{staticClass:"icon_img",attrs:{"src":item1.img_url,"alt":""}})]),_c('div',{class:("scene_txt " + (_vm.isSelectedId == item1.scene_id ? 'txt_c' : ''))},[_vm._v(" "+_vm._s(item1.scene_name)+" ")])])])}),(
                _vm.sceneForm[("scene_data" + (item.type))].scene_name &&
                item.type == _vm.sceneForm[("scene_data" + (item.type))].type
              )?_c('div',{staticClass:"scene_item",on:{"click":function($event){return _vm.onSelect(
                  _vm.sceneForm[("scene_data" + (item.type))],
                  _vm.sceneForm[("scene_data" + (item.type))].type
                )}}},[_c('div',{staticClass:"item_box"},[_c('div',{class:("img_box " + (_vm.isSelectedId ==
                    _vm.sceneForm[("scene_data" + (item.type))].scene_id
                      ? 'box_border'
                      : ''))},[_c('img',{staticClass:"icon_img",attrs:{"src":require('./../../assets/images/scene/pic_scene_default.png'),"alt":""}})]),_c('div',{class:("scene_txt " + (_vm.isSelectedId ==
                    _vm.sceneForm[("scene_data" + (item.type))].scene_id
                      ? 'txt_c'
                      : ''))},[_vm._v(" "+_vm._s(_vm.sceneForm[("scene_data" + (item.type))].scene_name)+" ")])])]):_vm._e(),_c('div',{staticClass:"scene_item",on:{"click":function($event){return _vm.openModal(item.type)}}},[_vm._m(0,true)])],2)])])}),0)]),_c('add-scene',{attrs:{"isShow":_vm.isOpenModal,"add-type":_vm.sceneType},on:{"update:isShow":function($event){_vm.isOpenModal=$event},"update:is-show":function($event){_vm.isOpenModal=$event},"addScene":_vm.handleAdd}}),_c('div',{staticClass:"safe_distance"}),_c('div',{staticClass:"footer"},[_c('van-button',{staticClass:"footer_btn btn1",attrs:{"color":"#111111","round":""},on:{"click":_vm.goBack}},[_c('img',{staticClass:"left_img",attrs:{"src":require("./../../assets/images/ido/pic_left.png"),"alt":""}})]),(_vm.isDisabled)?_c('van-button',{staticClass:"footer_btn btn2 mgl10",attrs:{"color":"#4BFFF2","round":""},on:{"click":_vm.toNext}},[_vm._v("继续 "),_c('img',{staticClass:"left_img",attrs:{"src":require("./../../assets/images/ido/pic_right.png"),"alt":""}})]):_c('van-button',{staticClass:"footer_btn btn3 mgl10",attrs:{"disabled":"","color":"#4BFFF2","round":""}},[_vm._v("请选择场景 ")])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item_box"},[_c('div',{staticClass:"img_box add_box"},[_c('img',{staticClass:"icon_img add_img",attrs:{"src":require("./../../assets/images/ido/icon_add.png"),"alt":""}})]),_c('div',{staticClass:"`scene_txt txt_c"},[_vm._v("新增")])])}]

export { render, staticRenderFns }